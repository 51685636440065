<template>
    <div class='lista-messaggi'>
        <div @scroll="scrollManuale" class="lista-messaggi__scroller">
            <div class="lista-messaggi__lista">
                <div class='lista-messaggi__day' v-for="(group) in dayGroups" 
                    :key="group.day"
                >
                    <div class="messaggio-data" >
                        {{group.day}}
                    </div>
                    <div class='lista-messaggi__avatar-group' v-for="(aGroup) in avatarGroup(group.messages)" 
                        :key="aGroup.id"
                    >
                        <div class="lista-messaggi__avatar-container">
                            <msp-avatar class="lista-messaggi__avatar" :utente="getUtente(aGroup.utenteId)"></msp-avatar>
                        </div>
                        <messaggio
                            class="lista-messaggi__messaggio"
                            v-for="message in aGroup.messages"
                            :key="message.id"
                            :messaggio="message"
                            :chat="chat"
                            :observer="observer"
                            :hasAvatar='false'
                        />
                    </div>
                </div>

        </div>
    </div>
            <v-btn
                :class="classButtonToBottom"
                fab
                dark
                x-small
                color="rgba(0,0,0,0.3)"
                class="to-bottom"
                @click = "scrollManualeToLast"
            >
                <i class="msp-icon-down"></i>
            </v-btn>
            </div>

</template>

<script type="module">
import _messaggi from "@mixins/messaggi.js"
import funzioni from "../../../js/msp/funzioni.js"
import messaggio from "./Messaggio.vue"
import MspAvatar from "./MspAvatar.vue"
import { mapGetters } from 'vuex'


const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
}
const locale = "it-IT";
const INTL = new Intl.DateTimeFormat(locale, options);


const component = {
    mixins: [
    ],
    components: {
        messaggio,
        MspAvatar,
    },
    props: [
        'chat',
        'messaggi',
        'refresh',
    ],
    data() {
        return {
            userHasScrolled: false,
            timeOffset: 0,
            observer: null,
            letti: [],
            showGoToBottom: false,
            nuovi: null,
            chatId: 0,
        }
    },
    computed: {
    ...mapGetters('calendario', [
      'events',
      'commenti',

    ]),
        me: function () {
            if(!this.chat) return false;
            let me = funzioni.deepProp(this.chat,'me');
            return me;
        },
        utentiTranneMe: function () {
            if(!this.chat) return false;
            let utenti = funzioni.deepProp(this.chat,'utenti');
            if (!utenti) return [];
            let mioId = this.me;
            return utenti.filter((ut)=>ut.id != mioId);
        },
        dayGroups: function () {
            let groups = [];
            let day = null;
            let count = -1;
            if (!this.messaggi) return groups;
            this.messaggi.forEach((message)=>{
                let  messageDay = this.giorno(message.locale_data_inviato);
                if (messageDay !== day) {
                    day = messageDay;
                    count +=1;
                    groups[count] = {
                        day: day,
                        messages: [],
                    }
                }
                groups[count].messages.push(message);
            });
            return groups;
        },

        classButtonToBottom: function () {
            if (this.showGoToBottom) {
                return "active";
            }
        }

    },


    mounted() {
        this.init();
    },

    watch: {
        refresh: function () {
            this.userHasScrolled = false;
            this.scrollToLast();
        },
        messaggi: function (nv, ov) {
            if (!nv) return;
            if (!ov || nv.length != ov.length) {
                this.scrollToLast();
            }
        },
    },

    methods: {
        getUtente: function (utente_id) {
            if (!this.chat) return null;
            if (!utente_id) return null;
            return this.chat.utenti.find(el=>el.id==utente_id);
        },
        getUtenteId: function (message) {
            return message.utente_id;
        },
        avatarGroup: function (messaggi) {
            let groups = [];
            let id = 0;
            let utenteId = null;
            let count = -1;
            if (!(messaggi && messaggi.length)) return groups;
            messaggi.forEach((message)=>{
                let currentUtenteId = this.getUtenteId(message);
                if (currentUtenteId !== utenteId) {
                    utenteId = currentUtenteId;
                    id = id + 1;
                    count +=1;
                    groups[count] = {
                        id: id,
                        utenteId: utenteId,
                        messages: [],
                    }
                }
                groups[count].messages.push(message);
            });
            return groups;
        },
            shortDate: function (value) {
                if (!value) return ''
                let shortDate;
                let date = new Date(value);
                let now = new Date();
                if (now.toDateString() === date.toDateString()) {
                    shortDate = date.toLocaleTimeString();
                } else {
                    shortDate = date.toLocaleDateString();
                }

                return shortDate;
            },
        init: async function () {
            this.chatId = this.chat && this.chat.id;
            this.timeOffset = this.getTimezoneOffset();
            this.intersectionObserverStart();
            this.scrollToLast();
            this.checkScroll();
        },

        getMessage: function (id) {
            return this.messaggi.find(el=>el.id==id);
        },

        getTimezoneOffset: function () {
            const d = new Date();
            return d.getTimezoneOffset() * 60 * 1000; // minuti di differenza dell'UTC
        },

        setLetti: function (a_ids) {
            a_ids.forEach(this.setLetto);
        },

        setLetto: function (id) {
            let message = this.getMessage(id);
            if(!message) return;
            if (message.utc_data_letto) return;  
            if (this.letti.indexOf(+id) === -1) {
                this.letti.push(+id);
            }
        },

        clearLetti: function (letti) {
            letti = letti.map(id=>+id);
            const difference = this.letti.filter((el)=>{
                return letti.indexOf(+el) === -1
            });
            this.letti = [...difference];
        },

        debouncedUpdateLetti: function () {
            funzioni.debounce(this.updateLetti,1000)();
        },

        updateLetti: async function () {
            if (!(this.letti && this.letti.length)) return;
            let a_ids = [...this.letti];
            // TODO: conta i messaggi aggiornati
            const countLetti = await _messaggi.setLetti(a_ids);
            if (countLetti) {
                this.$emit('read');
                _messaggi.postMessageUpdateFeeds();
            }
                this.clearLetti(a_ids);
        },

        updateObserverLetto: function (element) {
            let id = element.dataset.id;
            this.observer.unobserve(element);
            this.setLetto(id);
            this.debouncedUpdateLetti();
        },

        intersectionObserverCallback: function (entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.intersectionRatio > 0.7) {
                    this.updateObserverLetto(entry.segmento);
                }
            });
        },
        getListaDOM: function () {
            return this.$el.querySelector('.lista-messaggi__scroller');
        },

        intersectionObserverStart: function () {
            const root = this.getListaDOM();
            let options = {
                root: root,
                rootMargin: '0px',
                threshold: 1.0
            }

            this.observer = new IntersectionObserver(this.intersectionObserverCallback, options);
        },
        giorno: function (dateString) {
            let date = new Date(dateString);
            return INTL.format(date);
        },

        getDayMessage: function (message) {
            let date = new Date(message.timestamp_inviato*1000);
            return date.toDateString();
        },

        newDay: function (index) {
            if (index === 0) return true;
            let date1 = this.getDayMessage(this.messaggi[index]);
            let date2 = this.getDayMessage(this.messaggi[index - 1]);
            return date1 !== date2;
        },


        setAllMessagesRead: function () {
            let ids = this.messaggi && this.messaggi.map((el)=>(el.id));
            this.setLetti(ids);
            this.debouncedUpdateLetti();
        },

        scrollManuale: function () {
            this.userHasScrolled = true;
            this.checkScroll();
        },

        checkScroll: function () {
            this.$forceNextTick(()=>{
                const container =  this.getListaDOM();
                if (!container) return;
                const heightContainer = container.clientHeight;
                const heightList = container.firstChild.clientHeight;
                const scrollMax = container.scrollHeight - heightContainer;
                const scrollTop = container.scrollTop;
                const distance = scrollMax-scrollTop;
                const heightDiff = heightList - heightContainer;
                const thresholdGoToBottom = 200;
                const thresholdRead = 50;
                this.showGoToBottom = (distance && (distance > thresholdGoToBottom));
                if (distance > thresholdRead) this.setAllMessagesRead();
                if (heightDiff < thresholdRead ) this.setAllMessagesRead();
            },2000);
        },

        scrollManualeToLast: function () {
            this.userHasScrolled = false;
            this.scrollToLast();
        },

        scrollToLast: function () {
            if (this.userHasScrolled) return;
            this.setAllMessagesRead();
            this.$forceNextTick(()=>{
                let lista = this.getListaDOM();
                if (!lista) return;
                lista.scrollTop = lista.scrollHeight;

                let last = lista.lastChild;
                if (!last) return;
                this.updateObserverLetto(last);
            }, 0);

        },


        update: function () {
            this.$emit('update', this.index, {...this.dettaglio });
        },




        filterMilliseconds: function (ms) {
            let offset = this.timeOffset;
            if (!ms) return null;
            return ms - offset;
        },

        getMilliseconds: function (date) {
            return new Date(date).valueOf();
        },

        setLocaleDate: function (utc_date) {
            if (!utc_date) return null;
            const ms = this.filterMilliseconds(this.getMilliseconds(utc_date));
            return new Date(ms);
        },

        makeMessage: function (message) {
            return {
                ...message,
                locale_data_inviato: this.setLocaleDate(message.utc_data_inviato),
                locale_data_letto: this.setLocaleDate(message.utc_data_letto),
            }
        },

        makeMessages: function (messages) {
            let filteredMessages = messages.map(this.makeMessage);
            return filteredMessages;
        },


        findEventByCalendarioId: function (calendarioId) {
            if (!this.events) {
                return null;
            }
            return this.events.find((el)=>{
                return (el.data.calendario_id == calendarioId)
            });
        },
    }
}
export default component;
</script>

<style lang="scss">
.lista-messaggi {
    position: relative;
    flex: 1;
    width: 100%;
    margin: auto;
    flex-basis: 10%;
    height: 50%;

    &__messaggio {
        --border-radius-top: 15px 15px 0 0;
        padding-left: 50px;
        &+.lista-messaggi__messaggio {
            margin-top: -2px;
            --border-radius-top: 0 0 0 0;
            --border-radius-bottom: 0 0 0 0;
            &:last-child {
                --border-radius-bottom: 0 0 15px 0;
            }
        }
        &:last-child {
            --border-radius-bottom: 0 0 15px 0;
        }
    }

    &__avatar-group {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-end;
        position: relative;
    }

    &__avatar-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        height: 100%;
        text-align: left;
        z-index: 1;
        left: 0;
        display: flex;
        flex-direction: column-reverse;
    }

    &__avatar {
        position: sticky;
        top: 0;
        bottom: 5px;
        margin: 0;
        padding: 0;
    }
    &__scroller {
        height: 100%;
        background: var(--background-chat);
        overflow: auto;
        position: relative;
        width: 100%;
    }
    &__lista {
        height: auto;
        min-height: 100%;
        padding: 10px;
        position: relative;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    &__day {
        max-width: 600px;
    }
    .to-bottom {
        z-index: var(--z-top);
        font-size: 1em;
        position: absolute;
        bottom: 20px;
        top: auto;
        right: 20px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
        i:before {
            display: inline;
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
    .messaggio-data {
        z-index: var(--z-top);
        position: sticky;
        background: var(--col-msp);
        color: #fff;
        padding: 2px 10px;
        font-size: 0.8em;
        margin: 0 auto;
        text-align: center;
        display: inline-block;
        top: 0px;
        border-radius: 5px;
        pointer-event: none;
    }
}
</style>
